import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Heading, VStack, HStack, Button, useBreakpointValue, Spinner, Checkbox, CheckboxGroup,
  Select, FormControl, FormLabel, Grid, GridItem, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb,
  Radio, RadioGroup, useColorModeValue, Menu, MenuButton, MenuList, MenuItem,
  Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  useDisclosure, Stack, Switch, NumberInput, NumberInputField, NumberInputStepper,
  NumberIncrementStepper, NumberDecrementStepper, Textarea
} from '@chakra-ui/react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer,
  AreaChart, Area, BarChart, Bar, ScatterChart, Scatter, PieChart, Pie, ComposedChart
} from 'recharts';
import { stockDataManager } from './mockData';
import { FaChartLine, FaChartBar, FaChartPie, FaChartArea, FaDotCircle, FaTree } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';

const DataVisualization = () => {
  const [priceData, setPriceData] = useState([]);
  const [volumeData, setVolumeData] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [stockOptions, setStockOptions] = useState([]);
  const [dateRange, setDateRange] = useState({ minDate: new Date(), maxDate: new Date() });
  const [chartType, setChartType] = useState('line');
  const [isLoading, setIsLoading] = useState(true);
  const [comparisonStock, setComparisonStock] = useState('');
  const [timeFilter, setTimeFilter] = useState('all');
  const [liquidityThreshold, setLiquidityThreshold] = useState(1000000);
  const [marketCapThreshold, setMarketCapThreshold] = useState(1000000000);
  const [momentumPeriod, setMomentumPeriod] = useState(6);
  const [portfolioSize, setPortfolioSize] = useState(10);
  const [portfolioValue, setPortfolioValue] = useState(1000000);
  const [weightingMethod, setWeightingMethod] = useState('equal');
  const [showMeanValue, setShowMeanValue] = useState(false);
  const [secondaryChartType, setSecondaryChartType] = useState('radar');
  const [colorScheme, setColorScheme] = useState('default');
  const [dataUpdateInterval, setDataUpdateInterval] = useState(60000);
  const [showOutliers, setShowOutliers] = useState(false);
  const [correlationThreshold, setCorrelationThreshold] = useState(0.7);
  const [riskTolerance, setRiskTolerance] = useState('medium');
  const [rebalancingFrequency, setRebalancingFrequency] = useState('monthly');
  const [benchmarkIndex, setBenchmarkIndex] = useState('');
  const [showTrendlines, setShowTrendlines] = useState(false);
  const [volumeWeightedPrice, setVolumeWeightedPrice] = useState(false);
  const [movingAveragePeriods, setMovingAveragePeriods] = useState([20, 50, 200]);
  const [technicalIndicators, setTechnicalIndicators] = useState([]);
  const [fundamentalFactors, setFundamentalFactors] = useState([]);
  const [customFormulas, setCustomFormulas] = useState([]);
  const [dataTransformations, setDataTransformations] = useState([]);
  const [annotationMode, setAnnotationMode] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [alertThresholds, setAlertThresholds] = useState({});
  const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');
  const [currencyConversion, setCurrencyConversion] = useState('USD');
  const [darkMode, setDarkMode] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [dataExportFormat, setDataExportFormat] = useState('csv');
  const [heatmapMetric, setHeatmapMetric] = useState('correlation');

  const [cumulativeData, setCumulativeData] = useState([]);
  const [weights, setWeights] = useState({});

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const [fullDateRange, setFullDateRange] = useState({ minDate: new Date(), maxDate: new Date() });


  useEffect(() => {
    const initializeData = async () => {
      await stockDataManager.loadData();
      const stocks = stockDataManager.getStockList();
      setStockOptions(stocks);
      setSelectedStocks([stocks[0]]);
      const dateRange = stockDataManager.getDateRange();
      setFullDateRange(dateRange);
      setIsLoading(false);
    };
  
    initializeData();
  }, []);
  

  useEffect(() => {
    const initializeData = async () => {
      await stockDataManager.loadData();
      const stocks = stockDataManager.getStockList();
      setStockOptions(stocks);
      setSelectedStocks([stocks[0]]);
      setDateRange(stockDataManager.getDateRange());
      setIsLoading(false);
    };

    initializeData();
  }, []);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (autoRefresh) {
        fetchData();
      }
    }, dataUpdateInterval);

    return () => clearInterval(fetchDataInterval);
  }, [autoRefresh, dataUpdateInterval]);

  

  const calculateDateRange = useCallback(() => {
    const now = new Date();
    let minDate;
    switch (timeFilter) {
      case '1d':
        minDate = new Date(now);
        minDate.setDate(now.getDate() - 1);
        break;
      case '1w':
        minDate = new Date(now);
        minDate.setDate(now.getDate() - 7);
        break;
      case '1m':
        minDate = new Date(now);
        minDate.setMonth(now.getMonth() - 1);
        break;
      case '3m':
        minDate = new Date(now);
        minDate.setMonth(now.getMonth() - 3);
        break;
      case '6m':
        minDate = new Date(now);
        minDate.setMonth(now.getMonth() - 6);
        break;
      case '1y':
        minDate = new Date(now);
        minDate.setFullYear(now.getFullYear() - 1);
        break;
      case '5y':
        minDate = new Date(now);
        minDate.setFullYear(now.getFullYear() - 5);
        break;
      default:
        minDate = fullDateRange.minDate;
    }
    const maxDate = now;
    return { minDate, maxDate };
  }, [timeFilter, fullDateRange]);
  

  const fetchData = useCallback(async () => {
    if (selectedStocks.length > 0 && fullDateRange) {
      try {
        // Calculate the date range based on the time filter
        const { minDate, maxDate } = calculateDateRange();
        console.log('Selected Stocks:', selectedStocks);
        console.log('Date Range:', minDate, maxDate);
  
        // Fetch price data for each selected stock individually
        const dataPromises = selectedStocks.map(stock =>
          stockDataManager.getStockData([stock], minDate, maxDate)
        );
        const fetchedDataArrays = await Promise.all(dataPromises);
  
        // Concatenate the arrays
        const fetchedPriceData = [].concat(...fetchedDataArrays);
        console.log('Fetched Price Data:', fetchedPriceData);
  
        // Since the data is already in the correct format, set it directly
        setPriceData(fetchedPriceData);
  
        // Calculate cumulative data
        const cumulativeData = calculateCumulativeValue(fetchedPriceData);
        console.log('Cumulative Data:', cumulativeData);
  
        // Set cumulative data state
        setCumulativeData(cumulativeData);
  
        // Fetch volume data for each selected stock individually
        const volumeDataPromises = selectedStocks.map(stock =>
          stockDataManager.getVolumeData([stock], minDate, maxDate)
        );
        const fetchedVolumeDataArrays = await Promise.all(volumeDataPromises);
        const fetchedVolumeData = [].concat(...fetchedVolumeDataArrays);
        console.log('Fetched Volume Data:', fetchedVolumeData);
  
        // Set volume data state directly
        setVolumeData(fetchedVolumeData);
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('An error occurred while fetching data. Please try again.');
      }
    }
  }, [selectedStocks, weights, fullDateRange, calculateDateRange]);
  

  

  useEffect(() => {
    fetchData();
  }, [fetchData, timeFilter, weights]);

  const filteredStocks = useMemo(() => {
    // Step 1: Filter by Liquidity/Market Cap
    const filtered = stockOptions.filter(stock => {
      const adtv = stockDataManager.calculateADTV(stock, 30);
      const lastPrice = priceData[priceData.length - 1]?.[stock];
      const marketCap = lastPrice * 1000000; // Simplified market cap calculation
      return adtv >= liquidityThreshold && marketCap >= marketCapThreshold;
    });

    // Step 2: Recalculate top 100 most liquid
    const sortedByLiquidity = filtered.sort((a, b) => 
      stockDataManager.calculateADTV(b, 30) - stockDataManager.calculateADTV(a, 30)
    ).slice(0, 100);

    // Step 3: Rank by momentum
    const rankedByMomentum = sortedByLiquidity.sort((a, b) => {
      const momentumA = calculateMomentum(a, momentumPeriod);
      const momentumB = calculateMomentum(b, momentumPeriod);
      return momentumB - momentumA;
    });

    // Step 4: Rank by Adv/mean
    return rankedByMomentum.sort((a, b) => {
      const advMeanA = calculateAdvMean(a);
      const advMeanB = calculateAdvMean(b);
      return advMeanB - advMeanA;
    });
  }, [stockOptions, liquidityThreshold, marketCapThreshold, momentumPeriod, priceData]);

  const calculateMomentum = useCallback((stock, period) => {
    const prices = priceData.map(d => d[stock]).filter(Boolean);
    if (prices.length < period) return 0;
    return (prices[prices.length - 1] / prices[prices.length - period] - 1) * 100;
  }, [priceData]);

  const calculateAdvMean = useCallback((stock) => {
    const prices = priceData.map(d => d[stock]).filter(Boolean);
    const mean = prices.reduce((sum, price) => sum + price, 0) / prices.length;
    return stockDataManager.calculateADTV(stock, 30) / mean;
  }, [priceData]);

  const constructPortfolio = useMemo(() => {
    let remainingValue = portfolioValue;
    const portfolio = [];

    for (const stock of filteredStocks) {
      if (portfolio.length >= portfolioSize) break;

      const adtv = stockDataManager.calculateADTV(stock, 30);
      const maxPosition = adtv * 0.3 * 3; // 30% of ADTV for 3 days
      const lastPrice = priceData[priceData.length - 1]?.[stock];

      let stockWeight = weightingMethod === 'equal' ? 1 / portfolioSize : calculateVolatilityWeight(stock);
      let stockValue = portfolioValue * stockWeight;

      if (stockValue > maxPosition) {
        stockValue = maxPosition;
        stockWeight = stockValue / portfolioValue;
      }

      if (stockValue <= remainingValue) {
        portfolio.push({
          stock,
          weight: stockWeight,
          value: stockValue,
          shares: Math.floor(stockValue / lastPrice)
        });
        remainingValue -= stockValue;
      }
    }

    // Redistribute remaining value
    while (remainingValue > 0 && portfolio.length < filteredStocks.length) {
      const nextStock = filteredStocks[portfolio.length];
      const adtv = stockDataManager.calculateADTV(nextStock, 30);
      const maxPosition = adtv * 0.3 * 3;
      const lastPrice = priceData[priceData.length - 1]?.[nextStock];

      let stockValue = Math.min(remainingValue, maxPosition);
      let stockWeight = stockValue / portfolioValue;

      portfolio.push({
        stock: nextStock,
        weight: stockWeight,
        value: stockValue,
        shares: Math.floor(stockValue / lastPrice)
      });
      remainingValue -= stockValue;
    }

    return portfolio;
  }, [filteredStocks, portfolioSize, portfolioValue, weightingMethod, priceData]);

  const calculateVolatilityWeight = useCallback((stock) => {
    const returns = stockDataManager.calculateReturns(priceData, stock);
    const volatility = stockDataManager.calculateVolatility(returns);
    return 1 / volatility;
  }, [priceData]);

  const handleStockChange = useCallback((newSelectedStocks) => {
    const initialWeights = newSelectedStocks.reduce((acc, stock) => {
      acc[stock] = 1 / newSelectedStocks.length;
      return acc;
    }, {});
    setWeights(initialWeights);
    setSelectedStocks(newSelectedStocks);
  }, []);
  

  const handleWeightChange = useCallback((stock, value) => {
    setWeights(prev => ({ ...prev, [stock]: parseFloat(value) }));
  }, []);

  const toggleChartType = useCallback(() => {
    const types = ['line', 'area', 'bar', 'scatter', 'pie', 'composed', 'radar', 'treemap'];
    setChartType(prevType => types[(types.indexOf(prevType) + 1) % types.length]);
  }, []);

  const calculateCumulativeValue = useCallback((data) => {
    return data.map(day => {
      const cumulativeValue = selectedStocks.reduce((sum, stock) => {
        const weight = weights[stock] !== undefined ? weights[stock] : 1 / selectedStocks.length;
        return sum + (day[stock] || 0) * weight;
      }, 0);
      return { ...day, cumulativeValue };
    });
  }, [selectedStocks, weights]);
  

  const filterDataByTime = useCallback((data) => {
    const now = new Date();
    switch (timeFilter) {
      case '1d':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setDate(now.getDate() - 1)));
      case '1w':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setDate(now.getDate() - 7)));
      case '1m':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setMonth(now.getMonth() - 1)));
      case '3m':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setMonth(now.getMonth() - 3)));
      case '6m':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setMonth(now.getMonth() - 6)));
      case '1y':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setFullYear(now.getFullYear() - 1)));
      case '5y':
        return data.filter(d => new Date(d.DATE) >= new Date(now.setFullYear(now.getFullYear() - 5)));
      default:
        return data;
    }
  }, [timeFilter]);

  const renderCumulativeChart = useCallback(() => {
    return (
      <LineChart data={cumulativeData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="DATE" 
          tickFormatter={(date) => new Date(date).toLocaleDateString()} 
          angle={-45}
          textAnchor="end"
          height={70}
        />
        <YAxis />
        
        <Legend />
        <Line 
          type="monotone" 
          dataKey="cumulativeValue" 
          stroke="#8884d8" 
          fill="#8884d8"
          name="Cumulative Value"
          strokeWidth={2}
        />
        {comparisonStock && (
          <Line 
            type="monotone" 
            dataKey={comparisonStock} 
            stroke="#82ca9d" 
            fill="#82ca9d"
            name={comparisonStock}
            dot={false}
          />
        )}
      </LineChart>
    );
  }, [cumulativeData, comparisonStock]);

  const renderChart = useCallback(() => {
    const chartData = chartType === 'bar' ? volumeData : priceData;

    const ChartComponent = {
      'line': LineChart,
      'area': AreaChart,
      'bar': BarChart,
      'scatter': ScatterChart,
      'pie': PieChart,
      'composed': ComposedChart
    }[chartType] || LineChart;

    const DataComponent = {
      'line': Line,
      'area': Area,
      'bar': Bar,
      'scatter': Scatter,
      'pie': Pie
    }[chartType] || Line;

    const customTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box bg={bgColor} p={3} borderRadius="md" boxShadow="md">
            <Text fontWeight="bold">{new Date(label).toLocaleDateString()}</Text>
            {payload.map((entry, index) => (
              <Text key={index} color={entry.color}>
                {entry.name}: {entry.value.toFixed(2)}
              </Text>
            ))}
          </Box>
        );
      }
      return null;
    };

    return (
      <ChartComponent data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="DATE" 
          tickFormatter={(date) => new Date(date).toLocaleDateString()} 
          angle={-45}
          textAnchor="end"
          height={70}
        />
        <YAxis />
        <RechartsTooltip content={customTooltip} />
        <Legend />
        <DataComponent 
          type="monotone" 
          dataKey="compositeValue" 
          stroke="#8884d8" 
          fill="#8884d8"
          name="Composite Value"
          strokeWidth={2}
        />
        {showMeanValue && (
          <DataComponent 
            type="monotone" 
            dataKey="meanValue" 
            stroke="#82ca9d" 
            fill="#82ca9d"
            name="Mean Value"
            strokeWidth={2}
          />
        )}
        {comparisonStock && (
          <DataComponent 
            type="monotone" 
            dataKey={comparisonStock} 
            stroke="#ffc658" 
            fill="#ffc658"
            name={comparisonStock}
            dot={false}
          />
        )}
        {showTrendlines && (
          <Line 
            type="linear" 
            dataKey="compositeValue" 
            stroke="#ff7300" 
            dot={false} 
            activeDot={false} 
            name="Trendline"
          />
        )}
        {movingAveragePeriods.map((period, index) => (
          <Line 
            key={period}
            type="monotone"
            dataKey={`MA${period}`}
            stroke={`hsl(${index * 60}, 70%, 50%)`}
            dot={false}
            name={`${period}-day MA`}
          />
        ))}
      </ChartComponent>
    );
  }, [chartType, priceData, volumeData, comparisonStock, showMeanValue, showTrendlines, movingAveragePeriods, bgColor]);

  const renderSecondaryChart = useCallback(() => {
    // This function is no longer needed in the redesigned version
    // You can either remove all references to it or implement a placeholder
    return null;
  }, []);

  const renderControls = () => (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Select Stocks</FormLabel>
        <Box height="200px" overflowY="auto" borderWidth={1} borderRadius="md" p={2}>
          <CheckboxGroup colorScheme="blue" value={selectedStocks} onChange={handleStockChange}>
            <VStack align="start" spacing={2}>
              {stockOptions.map((stock) => (
                <Checkbox key={stock} value={stock}>{stock}</Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
      </FormControl>
      {selectedStocks.map(stock => (
        <HStack key={stock}>
          <Text width="100px">{stock}</Text>
          <NumberInput
            value={weights[stock]}
            onChange={(_, value) => handleWeightChange(stock, value)}
            step={0.01}
            min={0}
            max={1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
      ))}
      <FormControl>
        <FormLabel>Chart Type</FormLabel>
        <Menu>
          <MenuButton as={Button} rightIcon={<IoMdSettings />}>
            {chartType.charAt(0).toUpperCase() + chartType.slice(1)}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setChartType('line')} icon={<FaChartLine />}>Line</MenuItem>
            <MenuItem onClick={() => setChartType('area')} icon={<FaChartArea />}>Area</MenuItem>
            <MenuItem onClick={() => setChartType('bar')} icon={<FaChartBar />}>Bar</MenuItem>
            <MenuItem onClick={() => setChartType('scatter')} icon={<FaDotCircle />}>Scatter</MenuItem>
            <MenuItem onClick={() => setChartType('pie')} icon={<FaChartPie />}>Pie</MenuItem>
            <MenuItem onClick={() => setChartType('treemap')} icon={<FaTree />}>Treemap</MenuItem>
          </MenuList>
        </Menu>
      </FormControl>
      <FormControl>
        <FormLabel>Time Filter</FormLabel>
        <Select value={timeFilter} onChange={(e) => setTimeFilter(e.target.value)}>
          <option value="all">All Time</option>
          <option value="1d">Last Day</option>
          <option value="1w">Last Week</option>
          <option value="1m">Last Month</option>
          <option value="3m">Last 3 Months</option>
          <option value="6m">Last 6 Months</option>
          <option value="1y">Last Year</option>
          <option value="5y">Last 5 Years</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Liquidity Threshold</FormLabel>
        <Slider
          value={liquidityThreshold}
          onChange={(value) => setLiquidityThreshold(value)}
          min={0}
          max={10000000}
          step={100000}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color="blue.500" as={FaChartLine} />
          </SliderThumb>
        </Slider>
        <Text mt={2}>{liquidityThreshold.toLocaleString()}</Text>
      </FormControl>
      <FormControl>
        <FormLabel>Market Cap Threshold</FormLabel>
        <Slider
          value={marketCapThreshold}
          onChange={(value) => setMarketCapThreshold(value)}
          min={0}
          max={1000000000000}
          step={1000000000}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color="green.500" as={FaChartBar} />
          </SliderThumb>
        </Slider>
        <Text mt={2}>{marketCapThreshold.toLocaleString()}</Text>
      </FormControl>
      <FormControl>
        <FormLabel>Momentum Period</FormLabel>
        <NumberInput value={momentumPeriod} onChange={(_, value) => setMomentumPeriod(value)} min={1} max={100}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Portfolio Size</FormLabel>
        <NumberInput value={portfolioSize} onChange={(_, value) => setPortfolioSize(value)} min={1} max={100}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Portfolio Value</FormLabel>
        <NumberInput
          value={portfolioValue}
          onChange={(_, value) => setPortfolioValue(value)}
          min={1000}
          max={1000000000}
          step={1000}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Weighting Method</FormLabel>
        <RadioGroup value={weightingMethod} onChange={setWeightingMethod}>
          <Stack direction="row">
            <Radio value="equal">Equal</Radio>
            <Radio value="volatility">Volatility</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Show Mean Value</FormLabel>
        <Switch isChecked={showMeanValue} onChange={(e) => setShowMeanValue(e.target.checked)} />
      </FormControl>
      {chartType !== 'pie' && (
        <FormControl>
          <FormLabel>Comparison Stock</FormLabel>
          <Select 
            placeholder="Select stock to compare" 
            value={comparisonStock} 
            onChange={(e) => setComparisonStock(e.target.value)}
          >
            {selectedStocks.map(stock => (
              <option key={stock} value={stock}>{stock}</option>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Show Trendlines</FormLabel>
        <Switch isChecked={showTrendlines} onChange={(e) => setShowTrendlines(e.target.checked)} />
      </FormControl>
      <FormControl>
        <FormLabel>Moving Average Periods</FormLabel>
        <HStack>
          {movingAveragePeriods.map((period, index) => (
            <NumberInput
              key={index}
              value={period}
              onChange={(_, value) => {
                const newPeriods = [...movingAveragePeriods];
                newPeriods[index] = value;
                setMovingAveragePeriods(newPeriods);
              }}
              min={1}
              max={200}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          ))}
        </HStack>
      </FormControl>
    </VStack>
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box maxW="1200px" mx="auto" py={8} px={isMobile ? 4 : 0}>
      <Heading as="h1" size={isMobile ? "lg" : "xl"} mb={6}>
        Advanced Data Visualization
      </Heading>
      <Grid templateColumns={isMobile ? "1fr" : "repeat(3, 1fr)"} gap={6}>
        <GridItem colSpan={1}>
          <VStack spacing={4} align="stretch">
            <Button leftIcon={<IoMdSettings />} onClick={onOpen} colorScheme="blue">
              Open Settings
            </Button>
            {renderControls()}
          </VStack>
        </GridItem>
        <GridItem colSpan={isMobile ? 1 : 2}>
          <Box height={isMobile ? "300px" : "400px"} mb={8}>
            <ResponsiveContainer width="100%" height="100%">
              {renderCumulativeChart()}
            </ResponsiveContainer>
          </Box>
          <Box height={isMobile ? "300px" : "400px"} mb={8}>
            <ResponsiveContainer width="100%" height="100%">
              {renderChart()}
            </ResponsiveContainer>
          </Box>
          <Box height={isMobile ? "300px" : "400px"}>
            <ResponsiveContainer width="100%" height="100%">
              {renderSecondaryChart()}
            </ResponsiveContainer>
          </Box>
        </GridItem>
      </Grid>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Advanced Settings</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Color Scheme</FormLabel>
                <Select value={colorScheme} onChange={(e) => setColorScheme(e.target.value)}>
                  <option value="default">Default</option>
                  <option value="monochrome">Monochrome</option>
                  <option value="pastel">Pastel</option>
                  <option value="vibrant">Vibrant</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Data Update Interval (ms)</FormLabel>
                <NumberInput
                  value={dataUpdateInterval}
                  onChange={(_, value) => setDataUpdateInterval(value)}
                  min={1000}
                  max={300000}
                  step={1000}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Show Outliers</FormLabel>
                <Switch isChecked={showOutliers} onChange={(e) => setShowOutliers(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Correlation Threshold</FormLabel>
                <Slider
                  value={correlationThreshold}
                  onChange={(value) => setCorrelationThreshold(value)}
                  min={0}
                  max={1}
                  step={0.01}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Box color="teal.500" as={FaChartLine} />
                  </SliderThumb>
                </Slider>
                <Text mt={2}>{correlationThreshold.toFixed(2)}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Risk Tolerance</FormLabel>
                <RadioGroup value={riskTolerance} onChange={setRiskTolerance}>
                  <Stack direction="row">
                    <Radio value="low">Low</Radio>
                    <Radio value="medium">Medium</Radio>
                    <Radio value="high">High</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Rebalancing Frequency</FormLabel>
                <Select value={rebalancingFrequency} onChange={(e) => setRebalancingFrequency(e.target.value)}>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="annually">Annually</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Benchmark Index</FormLabel>
                <Select value={benchmarkIndex} onChange={(e) => setBenchmarkIndex(e.target.value)}>
                  <option value="">None</option>
                  <option value="SP500">S&P 500</option>
                  <option value="DJIA">Dow Jones Industrial Average</option>
                  <option value="NASDAQ">NASDAQ Composite</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Volume Weighted Price</FormLabel>
                <Switch isChecked={volumeWeightedPrice} onChange={(e) => setVolumeWeightedPrice(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Technical Indicators</FormLabel>
                <CheckboxGroup colorScheme="blue" value={technicalIndicators} onChange={setTechnicalIndicators}>
                  <VStack align="start">
                    <Checkbox value="RSI">RSI</Checkbox>
                    <Checkbox value="MACD">MACD</Checkbox>
                    <Checkbox value="BollingerBands">Bollinger Bands</Checkbox>
                    <Checkbox value="Stochastic">Stochastic Oscillator</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Fundamental Factors</FormLabel>
                <CheckboxGroup colorScheme="green" value={fundamentalFactors} onChange={setFundamentalFactors}>
                  <VStack align="start">
                    <Checkbox value="PE">P/E Ratio</Checkbox>
                    <Checkbox value="PB">P/B Ratio</Checkbox>
                    <Checkbox value="DividendYield">Dividend Yield</Checkbox>
                    <Checkbox value="EPS">EPS Growth</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Custom Formulas</FormLabel>
                <Textarea
                  value={customFormulas.join('\n')}
                  onChange={(e) => setCustomFormulas(e.target.value.split('\n'))}
                  placeholder="Enter custom formulas, one per line"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Data Transformations</FormLabel>
                <CheckboxGroup colorScheme="purple" value={dataTransformations} onChange={setDataTransformations}>
                  <VStack align="start">
                    <Checkbox value="log">Logarithmic</Checkbox>
                    <Checkbox value="percent">Percentage Change</Checkbox>
                    <Checkbox value="normalized">Normalized</Checkbox>
                  </VStack>
                </CheckboxGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Annotation Mode</FormLabel>
                <Switch isChecked={annotationMode} onChange={(e) => setAnnotationMode(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Alert Thresholds</FormLabel>
                {selectedStocks.map(stock => (
                  <HStack key={stock}>
                    <Text>{stock}:</Text>
                    <NumberInput
                      value={alertThresholds[stock] || ''}
                      onChange={(_, value) => setAlertThresholds(prev => ({ ...prev, [stock]: value }))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </HStack>
                ))}
              </FormControl>
              <FormControl>
                <FormLabel>Time Zone</FormLabel>
                <Select value={selectedTimeZone} onChange={(e) => setSelectedTimeZone(e.target.value)}>
                  <option value="UTC">UTC</option>
                  <option value="America/New_York">Eastern Time</option>
                  <option value="America/Chicago">Central Time</option>
                  <option value="America/Denver">Mountain Time</option>
                  <option value="America/Los_Angeles">Pacific Time</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Currency Conversion</FormLabel>
                <Select value={currencyConversion} onChange={(e) => setCurrencyConversion(e.target.value)}>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="JPY">JPY</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Dark Mode</FormLabel>
                <Switch isChecked={darkMode} onChange={(e) => setDarkMode(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Auto Refresh</FormLabel>
                <Switch isChecked={autoRefresh} onChange={(e) => setAutoRefresh(e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Data Export Format</FormLabel>
                <RadioGroup value={dataExportFormat} onChange={setDataExportFormat}>
                  <Stack direction="row">
                    <Radio value="csv">CSV</Radio>
                    <Radio value="json">JSON</Radio>
                    <Radio value="xlsx">Excel</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Heatmap Metric</FormLabel>
                <Select value={heatmapMetric} onChange={(e) => setHeatmapMetric(e.target.value)}>
                  <option value="correlation">Correlation</option>
                  <option value="covariance">Covariance</option>
                  <option value="beta">Beta</option>
                </Select>
              </FormControl>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

// Utility functions
const getColorScheme = (scheme) => {
  switch (scheme) {
    case 'monochrome':
      return ['#000000', '#333333', '#666666', '#999999', '#CCCCCC'];
    case 'pastel':
      return ['#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF'];
    case 'vibrant':
      return ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];
    default:
      return ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c'];
  }
};

const calculateMovingAverage = (data, period) => {
  return data.map((row, index, array) => {
    if (index < period - 1) return { ...row, MA: null };
    const sum = array.slice(index - period + 1, index + 1).reduce((acc, curr) => acc + curr.value, 0);
    return { ...row, MA: sum / period };
  });
};

const calculateBollingerBands = (data, period = 20, multiplier = 2) => {
  const sma = calculateMovingAverage(data, period);
  return sma.map((row, index, array) => {
    if (index < period - 1) return { ...row, upper: null, lower: null };
    const slice = array.slice(index - period + 1, index + 1);
    const std = Math.sqrt(slice.reduce((sum, item) => sum + Math.pow(item.value - item.MA, 2), 0) / period);
    return {
      ...row,
      upper: row.MA + multiplier * std,
      lower: row.MA - multiplier * std
    };
  });
};

const calculateRSI = (data, period = 14) => {
  let gains = 0;
  let losses = 0;
  return data.map((row, index, array) => {
    if (index < period) return { ...row, RSI: null };
    const change = row.value - array[index - 1].value;
    gains = (gains * (period - 1) + Math.max(change, 0)) / period;
    losses = (losses * (period - 1) + Math.max(-change, 0)) / period;
    const relativeStrength = gains / losses;
    return { ...row, RSI: 100 - (100 / (1 + relativeStrength)) };
  });
};

const calculateMACD = (data, shortPeriod = 12, longPeriod = 26, signalPeriod = 9) => {
  const emaShort = calculateEMA(data, shortPeriod);
  const emaLong = calculateEMA(data, longPeriod);
  const macdLine = emaShort.map((row, index) => ({
    ...row,
    MACD: row.EMA - emaLong[index].EMA
  }));
  const signalLine = calculateEMA(macdLine.map(row => ({ ...row, value: row.MACD })), signalPeriod);
  return signalLine.map((row, index) => ({
    ...row,
    MACDSignal: row.EMA,
    MACDHistogram: macdLine[index].MACD - row.EMA
  }));
};

const calculateEMA = (data, period) => {
  const k = 2 / (period + 1);
  let ema = data[0].value;
  return data.map((row) => {
    ema = row.value * k + ema * (1 - k);
    return { ...row, EMA: ema };
  });
};



export default DataVisualization;