import { readString } from 'react-papaparse';

class StockDataManager {
  constructor() {
    this.priceData = [];
  }

  async loadData() {
    try {
      const response = await fetch('prices.csv');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const rawData = await response.text();
      if (rawData.trim().startsWith('<!DOCTYPE html>')) {
        console.error('Received HTML instead of CSV. Check your server configuration.');
        return [];
      }
      this.priceData = this.parseCSV(rawData);
      console.log('Loaded price data:', this.priceData.length, 'rows');
      return this.priceData;
    } catch (error) {
      console.error('Error loading CSV data:', error);
      return [];
    }
  }

  parseCSV(data) {
    try {
      const parsedData = readString(data, { header: true, skipEmptyLines: true }).data;
      return parsedData.map(row => {
        if (!row.DATE) {
          console.warn('Row missing DATE:', row);
          return null;
        }
        const date = this.parseDate(row.DATE);
        if (!date) {
          console.warn('Invalid date:', row.DATE);
          return null;
        }
        return {
          DATE: date,
          ...Object.fromEntries(
            Object.entries(row)
              .filter(([key]) => key !== 'DATE')
              .map(([key, value]) => [key, parseFloat(value) || 0])
          )
        };
      }).filter(row => row !== null);
    } catch (error) {
      console.error('Error parsing CSV data:', error);
      return [];
    }
  }

  parseDate(dateString) {
    if (!dateString) return null;
    const [year, month, day] = dateString.split('-').map(Number);
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      console.warn('Invalid date components:', dateString);
      return null;
    }
    return new Date(year, month - 1, day);
  }

  getStockData(stock, startDate, endDate) {
    return this.priceData.filter(row => 
      row.DATE >= startDate && 
      row.DATE <= endDate && 
      row[stock] !== undefined
    );
  }

  getStockList() {
    if (this.priceData.length === 0) return [];
    return Object.keys(this.priceData[0]).filter(key => key !== 'DATE');
  }

  getDateRange() {
    if (this.priceData.length === 0) return { minDate: new Date(), maxDate: new Date() };
    const dates = this.priceData.map(row => row.DATE);
    return {
      minDate: new Date(Math.min(...dates)),
      maxDate: new Date(Math.max(...dates))
    };
  }

  calculateReturns(data, stock) {
    const prices = data.map(d => d[stock]);
    return prices.slice(1).map((price, i) => (price - prices[i]) / prices[i]);
  }

  calculateVolatility(returns) {
    const meanReturn = returns.reduce((a, b) => a + b, 0) / returns.length;
    const squaredDiffs = returns.map(r => Math.pow(r - meanReturn, 2));
    return Math.sqrt(squaredDiffs.reduce((a, b) => a + b, 0) / (returns.length - 1)) * Math.sqrt(252);
  }

  calculateADTV(stock, days) {
    const endDate = new Date(this.priceData[this.priceData.length - 1].DATE);
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - days);
    const data = this.getStockData(stock, startDate, endDate);
    return data.reduce((sum, day) => sum + day[stock], 0) / days;
  }

  getVolumeData(stock, startDate, endDate) {
    return this.getStockData(stock, startDate, endDate).map(row => ({
      ...row,
      [stock]: Math.floor(Math.random() * 1000000) // Random volume between 0 and 1,000,000
    }));
  }
}

export const stockDataManager = new StockDataManager();